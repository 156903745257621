import {
	Button,
	CheckBox,
	Divider,
	GenericTable,
	Input,
	LoadingProgress,
	Modal,
	Select
} from "@praticabr/ppsa";
import { LockIcon, SearchIcon, TrashIcon } from "lucide-react";
import React, { useContext, useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { UserContext } from "../../context";
import { PartnerProvider } from "../../context/PartnerContext";
import {
	managerUserType,
	updateAccountRequest
} from "../../models/usecases/users";
import { PartnerSearch } from "../../pages/Users/PartnerSearch";
import { store } from "../../store";
import { roles, tables } from "../../utils/constants";
import { AddButton } from "../AddButton";
import "./styles.scss";

type Props = {
	onClose: () => void;
	user: managerUserType;
};

export const ManageUserModal = ({ onClose, user }: Props) => {
	const [showModalSearchPartners, setShowModalSearchPartners] = useState(false);
	const [partnerIndex, setPartnerIndex] = useState<number | null>(null);
	const { onUpdateUser, isLoading, handleResetPassword } =
		useContext(UserContext);
	const { t } = useTranslation();
	const rolesNAdmin = user.roles?.filter((role: number) => role <= 2);
	const rolesAdmin = !!user.roles?.filter((role: number) => role > 2)?.length;

	const adminCS = store.getState().auth.roles.includes(4);

	const {
		handleSubmit,
		register,
		setValue,
		getValues,
		formState: { errors },
		control,
		getFieldState,
		watch
	} = useForm<updateAccountRequest>({
		defaultValues: {
			availablePartners: user.availablePartners.map((item) => ({
				...item,
				partnerId: item.id
			})),
			userId: user.id,
			email: user.email,
			contact: user.contact,
			roles: rolesNAdmin,
			partner: user.partner,
			adminRoles: user.roles?.filter((role: number) => role > 2)
		}
	});

	const { fields, append, remove, update } = useFieldArray({
		control,
		name: "availablePartners"
	});

	watch("roles");
	watch("availablePartners");

	return (
		<>
			<Modal.root>
				<Modal.header title={t("manageUserModal.title")} onClose={onClose} />
				<Modal.body>
					<Modal.body.main>
						<div className="manage-user-modal">
							<div className="content">
								<Divider title={t("manageUserModal.divider.partner")} />
								<div className="manage-user-modal-partner-table">
									<GenericTable
										data={[
											...fields.map((item, index) => ({
												...item,
												code: item.code || (
													<div className="input-code">
														<Input
															{...register(`availablePartners.${index}.code`, {
																required: true
															})}
															errors={
																getFieldState(`availablePartners.${index}.code`)
																	.error
															}
															variant={"light"}
														/>
														<div
															className="search-icon"
															onClick={() => {
																setShowModalSearchPartners(true);
																setPartnerIndex(index);
															}}
														>
															<SearchIcon color="var(--primary-light)" />
														</div>
													</div>
												),
												name: item.name || (
													<Input
														{...register(`availablePartners.${index}.name`, {
															required: true
														})}
														errors={
															getFieldState(`availablePartners.${index}.name`)
																.error
														}
														variant={"light"}
													/>
												),
												table: item.table || (
													<Select
														id="table"
														onSelectOptions={(option) =>
															setValue(
																`availablePartners.${index}.table`,
																option[0]
															)
														}
														options={tables}
														selectedOption={[
															getValues(`availablePartners.${index}.table`)
														]}
														errors={errors.partner?.table}
														placeholder={t(
															"manageUserModal.tableInput.placeholder"
														)}
														{...register(`availablePartners.${index}.table`, {
															required: {
																value: true,
																message: t(
																	"manageUserModal.tableInput.requiredAlert"
																)
															},
															minLength: {
																value: 3,
																message: t(
																	"manageUserModal.tableInput.lengthRequired"
																)
															},
															maxLength: {
																value: 3,
																message: t(
																	"manageUserModal.tableInput.lengthRequired"
																)
															}
														})}
													/>
												),
												selected:
													user.partner.id === item.partnerId
														? "Selecionado"
														: "",
												actionTable: (
													<>
														{fields.length > 1 && (
															<div
																onClick={() => remove(index)}
																className="action-icon"
															>
																<TrashIcon
																	size={"24px"}
																	color="var(--primary-light)"
																/>
															</div>
														)}
													</>
												)
											}))
										]}
										columns={[
											{
												key: "name",
												header: t("manageUserModal.inputPartnerName.label"),
												width: "35%",
												flex: true
											},
											{
												key: "code",
												header: t("manageUserModal.inputCode.label"),
												width: "20%"
											},
											{
												key: "table",
												header: t("manageUserModal.selectTable.label"),
												width: "25%"
											},
											{
												key: "selected",
												header: "",
												width: "calc(20% - 24px)"
											},
											{
												key: "actionTable",
												header: "",
												width: "24px"
											}
										]}
									/>
								</div>
								<div>
									{(rolesAdmin || (!rolesAdmin && fields.length === 0)) && (
										<AddButton
											label={t("manageUserModal.addButton")}
											onAddItem={() => setShowModalSearchPartners(true)}
										/>
									)}
								</div>
								<Divider title={t("manageUserModal.divider.account")} />
								<div className="form">
									<div className="form-item">
										<label htmlFor="contact">
											{t("manageUserModal.contactInput.label")}
										</label>
										<Input
											variant="light"
											defaultValue={user.contact}
											errors={errors.contact}
											placeholder={t(
												"manageUserModal.contactInput.placeholder"
											)}
											{...register("contact", {
												required: {
													value: true,
													message: t(
														"manageUserModal.contactInput.requiredAlert"
													)
												}
											})}
										/>
									</div>
									<div className="form-item">
										<label htmlFor="email">
											{t("manageUserModal.emailInput.label")}
										</label>
										<Input
											variant="light"
											defaultValue={user.email}
											placeholder={t("manageUserModal.emailInput.placeholder")}
											{...register("email", {
												required: {
													value: true,
													message: t("manageUserModal.emailInput.requiredAlert")
												},
												pattern: {
													value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
													message: t("manageUserModal.emailInput.invalidAlert")
												}
											})}
										/>
									</div>
								</div>
								<div className="reset-user-password-action">
									<LockIcon width="16" height="16" />
									<span onClick={() => handleResetPassword(user.id, onClose)}>
										{t("manageUserModal.resetPasswordAction")}
									</span>
								</div>
								<Divider title={t("manageUserModal.divider.roles")} />
								<div className="roles">
									{roles.slice(0, 2).map((role, index) => {
										return (
											<div className="checkbox" key={index}>
												<CheckBox
													format="square"
													variant="standard"
													id={role.label}
													type="checkbox"
													checked={getValues("roles")?.includes(role.value)}
													onChange={(e) =>
														getValues("roles")[0] === role.value
															? setValue("roles", [])
															: setValue("roles", [role.value])
													}
												/>
												<label htmlFor={role.label}>{role.label}</label>
											</div>
										);
									})}
									{adminCS &&
										roles.slice(2, 4).map((role, index) => {
											return (
												<div className="checkbox" key={index}>
													<CheckBox
														format="square"
														variant="standard"
														id={role.label}
														type="checkbox"
														defaultChecked={getValues("adminRoles")?.includes(
															role.value
														)}
														onChange={(e) => {
															const newAdminRoles = [
																...(getValues("adminRoles") || [])
															];
															if (e.target.checked) {
																newAdminRoles.push(role.value);
																setValue("adminRoles", newAdminRoles);
															} else {
																newAdminRoles.splice(
																	newAdminRoles.indexOf(role.value),
																	1
																);
																setValue("adminRoles", newAdminRoles);
															}
														}}
													/>
													<label htmlFor={role.label}>{role.label}</label>
												</div>
											);
										})}
									{errors.roles && (
										<p>{t("manageUserModal.roleInput.checkedAlert")}</p>
									)}
								</div>
							</div>
						</div>
					</Modal.body.main>
					<Modal.body.actions>
						<div className="manage-user-modal-actions">
							<Button
								title={t("manageUserModal.submitAction")}
								size="lg"
								variant="confirmation-solid"
								onClick={handleSubmit((data) =>
									onUpdateUser({ data, callback: onClose })
								)}
							/>
							<Button
								size="lg"
								title={t("manageUserModal.cancelAction")}
								variant="cancellation"
								onClick={onClose}
							/>
						</div>
					</Modal.body.actions>
				</Modal.body>
			</Modal.root>
			{isLoading && <LoadingProgress />}
			{showModalSearchPartners && (
				<PartnerProvider>
					<PartnerSearch
						onClose={() => setShowModalSearchPartners(false)}
						onChangePartner={(partner: any) => {
							append(partner);
							setShowModalSearchPartners(false);
						}}
						arrayPartners={fields}
					/>
				</PartnerProvider>
			)}
		</>
	);
};
