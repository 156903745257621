import { FileSliders } from "lucide-react";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { LuListTodo, LuMenu, LuStore, LuUsers } from "react-icons/lu";
import { useParams } from "react-router-dom";
import PIcon from "../../assets/SvgIcons/PIcon";
import { AuthContext } from "../../context";
import { useViewVariable } from "../../context/ViewContext";
import { store } from "../../store";
import { MenuPage } from "../MenuPage";
import { NavBar } from "../NavBar";
import { Notification } from "../Notification";
import NotificationsModal from "../NotificationsModal";
import "./styles.scss";

type TParams = {
	children?: React.ReactNode;
};

export const DefaultLayoutRoot = ({ children }: TParams) => {
	const { roles } = useContext(AuthContext).user;
	const { c5Filial, c5Num } = useParams();
	const { mobileSize } = useViewVariable();
	const { t, i18n } = useTranslation();

	const [showNotification, setShowNotification] = useState<boolean>(false);
	const [showMenu, setShowMenu] = useState<boolean>(false);

	const language = i18n.language || store.getState().auth.language;
	const isAdmin = roles?.includes(4) || roles?.includes(3);
	const pathname = location.pathname;

	const listLinks = [
		{
			hasHole: true,
			icon: <LuListTodo size={32} />,
			title: t("navbar.catalog"),
			joyride: "joyride-catalog",
			link: `/${language}/`,
			activeLinkClass: showMenu ? [""] : [`/${language}/`]
		},
		{
			hasHole: true,
			icon: <LuStore size={32} />,
			title: t("navbar.orders"),
			link: `/${language}/requests`,
			joyride: "joyride-requests",
			activeLinkClass: showMenu
				? [""]
				: [
						`/${language}/requests`,
						`/${language}/newRequest`,
						`/${language}/request/${pathname.split("/")[3]}`
					]
		},
		{
			hasHole: true,
			icon: <PIcon />,
			title: t("navbar.praticaMenu"),
			joyride: "joyride-pratica-menu",
			link: `/${language}/pratica/orders`,
			activeLinkClass: showMenu
				? [""]
				: [
						`/${language}/pratica/billets`,
						`/${language}/pratica/partsToReturn`,
						`/${language}/pratica/orders`,
						`/${language}/pratica/order/${c5Filial}/${c5Num}`
					]
		},
		{
			hasHole: isAdmin,
			icon: <FileSliders size={32} />,
			title: t("navbar.editorCatalog"),
			link: `/${language}/editor/catalog`,
			joyride: "joyride-editor-catalog",
			activeLinkClass: showMenu
				? [""]
				: [`/${language}/private/catalog`, `/${language}/editor/catalog`]
		},
		{
			hasHole: isAdmin,
			icon: <LuUsers size={32} />,
			title: t("navbar.users"),
			link: `/${language}/users`,
			joyride: "joyride-users",
			activeLinkClass: showMenu ? [""] : [`/users`]
		}
	];

	useEffect(() => {
		setShowMenu(false);
	}, [pathname]);

	return (
		<div className="main-content-module">
			<NavBar.root>
				{mobileSize && (
					<>
						<li
							className={`list-item ${showMenu && "active"}`}
							onClick={() => setShowMenu(!showMenu)}
						>
							<a>
								<LuMenu size={32} />
							</a>
						</li>
						{listLinks.filter((option) => option.hasHole).length === 1 &&
							[...Array(0)].map((option, index) => (
								<li
									className="list-item"
									style={{ visibility: "hidden" }}
									key={index}
								></li>
							))}
						{listLinks.filter((option) => option.hasHole).length === 2 &&
							[...Array(1)].map((option, index) => (
								<li
									className="list-item"
									style={{ visibility: "hidden" }}
									key={index}
								></li>
							))}
						{listLinks.filter((option) => option.hasHole).length > 2 &&
							[...Array(3)].map((option, index) => (
								<li
									className="list-item"
									style={{ visibility: "hidden" }}
									key={index}
								></li>
							))}
					</>
				)}
				{(!mobileSize ||
					listLinks.filter((option) => option.hasHole).length <= 3) &&
					listLinks.map(
						(item, index) =>
							item.hasHole && (
								<NavBar.linkList
									key={index}
									link={item.link}
									title={item.title}
									icon={item.icon}
									activeLinkClass={item.activeLinkClass}
									joyride={item?.joyride}
								/>
							)
					)}
				{mobileSize && (
					<li
						className={`list-item ${showNotification && "active"}`}
						onClick={() => setShowNotification(!showNotification)}
					>
						<a>
							<Notification colorBell="white" />
						</a>
					</li>
				)}
			</NavBar.root>
			{mobileSize &&
				(listLinks.filter((option) => option.hasHole).length > 3 ? (
					<MenuPage
						show={showMenu}
						onClose={() => setShowMenu(false)}
						data={listLinks}
					/>
				) : (
					<MenuPage show={showMenu} onClose={() => setShowMenu(false)} />
				))}
			<main className="main-article">{children}</main>
			{showNotification && (
				<NotificationsModal onClose={() => setShowNotification(false)} />
			)}
		</div>
	);
};
