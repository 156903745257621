import axios, { AxiosRequestConfig } from "axios";
import { upsertProductSchema } from "../components/ManageProductModal";
import i18n from "../i18n/i18n";
import {
	AuthZendesk,
	GetNotificationsTypes,
	GetTechnicalSupportById,
	updateNotificationsTypes
} from "../models/usecases";
import { AddSaleRecord } from "../models/usecases/add-sale-record";
import { DeleteSaleRecordById } from "../models/usecases/delete-sale-record-by-id";
import { GetAccounts } from "../models/usecases/get-accounts";
import { GetDefects } from "../models/usecases/get-defects";
import { GetDistributorDashboard } from "../models/usecases/get-distributor-dashboard";
import { GetDistributors } from "../models/usecases/get-distributors";
import { GetEquipments } from "../models/usecases/get-equipments";
import { GetNotifications } from "../models/usecases/get-notifications";
import { GetPartsList } from "../models/usecases/get-parts-list";
import { GetSaleRecordById } from "../models/usecases/get-sale-record-by-id";
import { Sc5010Overview } from "../models/usecases/get-sc5010-overview";
import { GetSc5010sCustomList } from "../models/usecases/get-sc5010s-custom-list";
import { GetSalesParams, GetSalesResponse } from "../models/usecases/sales";
import { Sb1010sCustomList } from "../models/usecases/sb1010s-custom-list";
import { UpdateNotifications } from "../models/usecases/update-notifications";
import { UpdateSaleRecordById } from "../models/usecases/update-sale-record-by-id";
import {
	authenticateResponse,
	loginRequest,
	newPasswordRequest,
	registerRequest,
	updateAccountLanguageRequest,
	updateAccountLanguageResponse,
	updateAccountRequest
} from "../models/usecases/users";
import ListParams from "../utils/ContextUtils";

const axiosConfig: AxiosRequestConfig = {
	baseURL: import.meta.env.VITE_REACT_APP_API_URL,
	headers: { "X-Custom-Header": "foobar" },
	validateStatus: (status) => {
		if (status >= 400) {
			if (window.location.pathname !== `/${i18n.language}/error`) {
				window.location.href = `/${i18n.language}/error`;
			}
		}
		return status >= 200 && status < 400;
	},
	transformResponse: [
		function (data) {
			if (data) {
				return JSON.parse(data);
			}
			return data;
		}
	]
};

export const api = axios.create(axiosConfig);

// users-routes
export const getDataUser = (authorization: string) =>
	api.get<any>(`/users/overview`);

export const createOrder = (data: any) => api.post(`Orders/createOrder`, data);
export const updateOrder = (data: any) =>
	api.put(`Orders/updateOrderById`, data);

export const register = (data: registerRequest) =>
	api.post<authenticateResponse>(`/signup`, data);

export const updateAccountLanguage = ({
	data,
	id
}: {
	data: updateAccountLanguageRequest;
	id: number;
}) => api.put<updateAccountLanguageResponse>(`/accounts/${id}/language`, data);

export const updateSubscriptionAccount = (subscription: any) =>
	api.put(`/subscription`, { subscription });

export const updateAccount = ({
	data,
	id
}: {
	data: updateAccountRequest;
	id: number;
}) => api.put<authenticateResponse>(`/accounts/${id}`, data);

export const updatePartnerId = (params: {
	userId: number;
	partnerId: number;
}) => {
	return api.put(`/users/updatePartnerId`, {
		partnerId: params.partnerId
	});
};

export const getAccountsList = (params: GetAccounts.Params) => {
	const search = params?.term || "";
	const limit = `&limit=${params.limit}`;
	const offset = `&offset=${params.offset}`;

	return api.get<GetAccounts.Return>(
		`/accounts?term=${search}${limit}${offset}`
	);
};

export const getSc5010sCustomList = (params: GetSc5010sCustomList.Params) => {
	return api.get<GetSc5010sCustomList.Return>(`/Sc5010s/customList`, {
		params
	});
};

export const onGetSc5010Overview = (params: {
	c5Filial: string;
	c5Num: string;
}) => {
	return api.get<Sc5010Overview>(`/Sc5010s/overview`, { params });
};

export const getPartsToReturn = (params: ListParams) => {
	return api.get(`/Zeg010s/getCustomList`, { params });
};

export const getOrders = (params: ListParams) => {
	return api.get(`/Orders/customList`, {
		params
	});
};

export const getOrderById = (params: { id: number }) => {
	return api.get(`/Orders/getOrderById`, {
		params
	});
};

export const deleteOrderById = (params: { id: number }) => {
	return api.delete(`/Orders/deleteOrderById`, {
		params
	});
};

export const putStatusOrder = (data: any) => {
	return api.put(`/Orders/updateStatusOrder`, data);
};

export const cancelRequestById = (data: any) => {
	return api.put(`/Orders/cancelRequestById`, data);
};

export const handleRequest = (data: any) => {
	return api.put(`/Orders/handleRequest`, data);
};

export const getProducts = (
	params: ListParams,
	tags: number[],
	subTags: number[]
) => {
	return api.get<Sb1010sCustomList.Return>(`/Products/customList`, {
		params: { ...params, tags, subTags }
	});
};

export const getSb1010 = (
	params: ListParams,
	tags: number[],
	subTags: number[]
) => {
	return api.get<Sb1010sCustomList.Return>(`/Sb1010s/customList`, {
		params: { ...params, tags, subTags }
	});
};

export const getTags = () => api.get(`/Tags`);
export const getSubTags = () => api.get(`/SubTags`);

export const upsertProduct = (data: upsertProductSchema) => {
	return api.post<Sb1010sCustomList.Return>(`/Products/customUpsert`, data);
};

export const getBillets = (params: ListParams) => {
	return api.get<Sb1010sCustomList.Return>(`/Se1010s/getBillets`, {
		params
	});
};

export const getListDistributors = (params: GetDistributors.Params) => {
	const search = params.term ? params.term : ``;
	const limit = `&limit=${params.limit}`;
	const offset = `&offset=${params.offset}`;
	return api.get<GetDistributors.Return>(
		`/distributors?term=${search}${limit}${offset}`
	);
};

export const updateAccountTutorials = (data: { tutorials: any } | []) => {
	return api.put(`/accounts-tutorials`, { tutorials: data });
};

// sales-routes
export const getSaleRecordById = (data: GetSaleRecordById.Params) => {
	return api.get<GetSaleRecordById.Return>(`/sales-records/${data.id}`);
};

export const newSaleRecord = (data: AddSaleRecord.Params) => {
	return api.post<AddSaleRecord.Return>(`/sales-records`, data);
};

export const updateSaleRecord = (
	data: UpdateSaleRecordById.Params,
	id: number
) => {
	return api.put<UpdateSaleRecordById.Return>(`/sales-records/${id}`, data);
};

export const deleteSaleRecord = (params: DeleteSaleRecordById.Params) =>
	api.delete<DeleteSaleRecordById.Return>(`/sales-records/${params.id}`);

export const getListSales = (params: GetSalesParams) => {
	const search = params.term ? params.term : ``;
	const limit = `&limit=${params.limit}`;
	const offset = `&offset=${params.offset}`;
	const sold = params.sold ? `&sold=${params.sold}` : ``;

	return api.get<GetSalesResponse>(
		`/equipments?term=${search}${limit}${offset}${sold}`
	);
};

// technical-support-routes
export const getTechnicalSupportById = (
	data: GetTechnicalSupportById.Params
) => {
	return api.get<GetTechnicalSupportById.Return>(
		`/technical-support/${data.id}`
	);
};

export const getListParts = (params: any) => {
	const search = params.term ? params.term : ``;
	const limit = `&limit=${params.limit}`;
	const offset = `&offset=${params.offset}`;

	return api.get<GetPartsList.Return>(`parts?term=${search}${limit}${offset}`);
};

export const getListDefects = (params: any) => {
	const search = params.term ? params.term : ``;
	const limit = `&limit=${params.limit}`;
	const offset = `&offset=${params.offset}`;

	return api.get<GetDefects.Return>(`defects?term=${search}${limit}${offset}`);
};

// equipments
export const getEquipmentsList = (params: GetEquipments.Params) => {
	const search = params?.term || "";
	const limit = `&limit=${params.limit}`;
	const offset = `&offset=${params.offset}`;
	const clientId = params.clientId ? `&clientId=${params?.clientId}` : ``;
	const sold = params.sold ? `&sold=${params.sold}` : ``;

	return api.get<GetEquipments.Return>(
		`/equipments?term=${search}${limit}${offset}${clientId}${sold}`
	);
};

// upload aws
export const upload = (
	file: any,
	callback: (percentCompleted: number) => void
) => {
	const formData = new FormData();
	formData.append("file", file.file, file.name);
	return api.post(`/upload`, formData, {
		onUploadProgress: (progressEvent) => {
			if (progressEvent.total !== null && progressEvent.total !== undefined) {
				const percentCompleted = Math.round(
					(progressEvent.loaded * 100) / progressEvent.total
				);
				callback(percentCompleted);
			} else {
				console.log("Progresso do Upload: Tamanho total desconhecido.");
			}
		}
	});
};
// Dashboard

export const getDashboardList = (data: GetDistributorDashboard.Params) => {
	return api.post<GetDistributorDashboard.Return>(
		`/distributors/dashboard`,
		data
	);
};

export const authenticateZendesk = (data: AuthZendesk.Params) => {
	return api.post<AuthZendesk.Return>(`/auth/zendesk`, data);
};

export const authenticateZendeskSunco = (data: AuthZendesk.Params) => {
	return api.post(`/auth/zendesk-sunco`, data);
};

// Notifications

export const getNotificationsList = (params: GetNotifications.Params) => {
	const search = params?.term || "";
	const limit = `&limit=${params.limit}`;
	const offset = `&offset=${params.offset}`;
	const status = params.status ? `&status=${params.status}` : "";
	const userId = params.userId ? `&userId=${params.userId}` : "";

	return api.get<GetNotifications.Return>(
		`/notifications/customList?term=${search}${limit}${offset}${status}${userId}`
	);
};

export const getNotificationsTypes = () => {
	return api.get<GetNotificationsTypes.Return[]>(`/NotificationsTypes`);
};

export const updateNotificationStatusById = (
	data: UpdateNotifications.Params
) => {
	return api.put(`/notifications/updateNotification`, data);
};

export const updateNotificationStatusAll = () => {
	return api.put(`/notifications/updateAllNotifications`);
};

export const updateNotificationUserType = (
	data: updateNotificationsTypes.Params
) => {
	return api.put<updateNotificationsTypes.Return>(`/notification/update`, data);
};

export const updateNotificationManager = (userId: number, param: any) => {
	return api.put(
		`/NotificationManagers/customUpdateNotifications?userId=${userId}`,
		param
	);
};

export const login = (data: loginRequest) =>
	api.post<any>(`/users/login`, data);

export const getUserList = (data: any) => {
	const term = `&term=${data.term || ""}`;
	const limit = `&limit=${data.limit}`;
	const offset = `&offset=${data.offset}`;
	return api.get(`/users/listUsers?${offset}${limit}${term}`);
};

export const getPartnerList = (data: any) => {
	const term = `&term=${data.term || ""}`;
	const limit = `&limit=${data.limit}`;
	const offset = `&offset=${data.offset}`;
	const arrayPartners =
		data.arrayPartners?.length > 0
			? `&arrayPartners=${JSON.stringify(data.arrayPartners)}`
			: `&arrayPartners=${JSON.stringify([])}`;
	return api.get(
		`/Partners/listPartners?${offset}${limit}${arrayPartners}${term}`
	);
};

export const createNewPartner = (data: any) =>
	api.post("/Partners/customRegister", data);

export const handleUpdatePartner = (data: any) =>
	api.put(`/Partners/customUpdate`, data);

export const getDealerList = (params: any) => {
	const term = `&term=${params.term || ""}`;
	const limit = `&limit=${params.limit}`;
	const offset = `&offset=${params.offset}`;
	return api.get<any>(`Partners/listDealers?${offset}${limit}${term}`);
};

export const getSellerList = (params: any) => {
	const search = params.term ? params.term : ``;
	return api.get<any>(`Partners/listSellers?term=${search}`);
};

export const deleteFile = (id: number) =>
	api.delete(`/UploadFiles/customDelete/${id}`);

export const createNewUser = (data: any) =>
	api.post("/users/customRegister", data);

export const handleUpdateUser = (userId: number, data: any) =>
	api.post(`/users/customUpdate?userId=${userId}`, data);

export const userUpdateProfile = (userId: number, data: any) =>
	api.post(`/users/customUpdateProfile?userId=${userId}`, data);

export const newPassword = (userId: number, data: newPasswordRequest) =>
	api.post(`/users/customUpdatePassword?userId=${userId}`, data);

export const customResetPassword = (userId: number) =>
	api.post(`/users/customResetPassword?userId=${userId}`);

export const getTechnicalList = (data: any) => {
	const term = `&term=${data.term || ""}`;
	const limit = `&limit=${data.limit}`;
	const offset = `&offset=${data.offset}`;
	return api.get(`/Sa1010s/viewTechnicals?${offset}${limit}${term}`);
};

// TAGS
export const getListTags = (data: any) =>
	api.get(`/Tags/listTags?term=${data.term || ""}`);

export const createTag = (data: any) => api.post("Tags/customRegister", data);

export const deleteTag = (data: any) => api.post("Tags/deleteTag", data);

export const updateTag = (data: any) => api.post("Tags/updateTag", data);

export const findActiveTags = (id: number) =>
	api.get(`/TagsOnProducts/findProductWithTag?id=${id}`);

// SUBTAGS
export const getListSubTags = (data: any) =>
	api.get(`/SubTags/listSubTags?term=${data.term || ""}`);

export const createSubTag = (data: any) =>
	api.post("/SubTags/customRegister", data);

export const deleteSubTag = (data: any) =>
	api.post("/SubTags/deleteSubTag", data);

export const updateSubTag = (data: any) =>
	api.post("/SubTags/updateSubTag", data);

export const findActiveSubTags = (id: number) =>
	api.get(`/SubTagsOnProducts/findProductWithSubTag?id=${id}`);

// EMAIL
export const forgotMyPass = async (data: any) =>
	api.post(`/users/forgotMyPass`, data);

export const recoveryPass = async (data: any) =>
	api.post(`users/recovery`, data);

// BILLET
export const getPDFBillet = async (data: any) => {
	const { e1Numbor, e1Prefixo, e1Num, e1Parcela, e1Tipo } = data;
	return api.get(
		`/Se1010s/getPDFBillet?e1Numbor=${e1Numbor}&e1Prefixo=${e1Prefixo}&e1Num=${e1Num}&e1Parcela=${e1Parcela}&e1Tipo=${e1Tipo}.pdf`
	);
};
