import {
	Button,
	GenericTable,
	LoadingList,
	Modal,
	Paginator,
	Search
} from "@praticabr/ppsa";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import SearchSvg from "../../assets/SvgIcons/SearchSvg";
import { Sb1010sContext } from "../../context/Sb1010sContext";
import { Sb1010sCustomList } from "../../models/usecases/sb1010s-custom-list";
import { tables } from "../../utils/constants";
import "./styles.scss";

interface Props {
	onClose: (value: boolean) => void;
	previousSelectedItens: any[];
	onConfirmSetSelectedEquipments: (value: any[]) => void;
}

export const ProductSearch = ({
	onClose,
	previousSelectedItens,
	onConfirmSetSelectedEquipments
}: Props) => {
	const { sb1010list, onPageChange, onSearchParams, total, offset, isLoading } =
		useContext(Sb1010sContext);
	const [selectedItens, setSelectedEquipments] = useState<any[]>([]);
	const user = useSelector((state: any) => state.auth);
	const { t } = useTranslation();

	useEffect(() => {
		onSearchParams("");
	}, []);

	const handleSelectItems = (item: any) => {
		const formattedItem = {
			id: item?.id,
			code: item?.b1Cod,
			description: item?.b1Desc,
			amount: 0,
			price: item?.tablePrice || 0,
			product: {
				b1Ipi: item?.ipi,
				sgi010: item?.sgi010
			}
		};

		const currentIndex = selectedItens.findIndex(
			(equipment) =>
				equipment.id === formattedItem.id &&
				equipment.code === formattedItem.code
		);

		const newChecked = [...selectedItens];

		if (currentIndex === -1) {
			newChecked.push(formattedItem);
		} else {
			newChecked.splice(currentIndex, 1);
		}
		setSelectedEquipments(newChecked);
	};

	const valueWithDiscount = (item: Sb1010sCustomList.List) => {
		const product = item?.da1010?.filter((i) => i?.da1Codtab === "C20")[0];
		const table = tables?.filter(
			(table) => table?.value === user?.partner?.table
		)[0];
		const result =
			table?.value === "C20"
				? product?.da1Prcven
				: (product?.da1Prcven * 100) / table?.discount;

		return product?.da1Prcven ? result : "Preço não encontrado";
	};

	return (
		<Modal.root>
			<Modal.header
				onClose={() => onClose(false)}
				title={t("productSearchModal.title")}
			/>
			<Modal.body>
				<Modal.body.main>
					<div className="product-search-modal">
						<Search.root>
							<Search.icon>
								<SearchSvg />
							</Search.icon>
							<Search.input
								onChangeValues={onSearchParams}
								placeholder={t("productSearchModal.searchPlaceholder")}
							/>
						</Search.root>
						<div className="list">
							{isLoading ? (
								<LoadingList />
							) : (
								<GenericTable
									onRowClicked={handleSelectItems}
									data={sb1010list.map((item) => {
										return {
											...item,
											id: item?.rECNO,
											product: `${item?.b1Cod} - ${item?.b1Desc}`,
											tipo: item?.b1Tipo,
											tablePrice:
												item?.da1010?.filter((i) => i?.da1Codtab === "C20")[0]
													?.da1Prcven || "Preço não encontrado",
											conditionalPrice: valueWithDiscount(item),
											ipi: item?.b1Ipi
										};
									})}
									columns={[
										{
											key: "product",
											checkbox: true,
											header: t("productSearchModal.tableColumns.product"),
											width: "40%",
											selected: selectedItens,
											flex: true
										},
										{
											key: "tablePrice",
											header: t("productSearchModal.tableColumns.tablePrice"),
											width: "20%",
											flex: true
										},
										{
											key: "conditionalPrice",
											header: t(
												"productSearchModal.tableColumns.conditionalPrice"
											),
											width: "20%",
											flex: true
										},
										{
											key: "tipo",
											header: t("productSearchModal.tableColumns.tipo"),
											width: "10%"
										},
										{
											key: "ipi",
											header: t("productSearchModal.tableColumns.ipi"),
											width: "10%"
										}
									]}
								/>
							)}
						</div>
						<div className="paginator-main-content">
							<Paginator
								totalPages={total}
								currentPage={offset}
								onPageChange={(page: number) => onPageChange(page)}
							/>
						</div>
					</div>
				</Modal.body.main>
				<Modal.body.actions>
					<div className="product-search-modal-actions">
						<Button
							title={t("productSearchModal.submitAction")}
							size="lg"
							variant="confirmation-solid"
							onClick={() => onConfirmSetSelectedEquipments(selectedItens)}
							disabled={selectedItens.length === 0 || isLoading}
						/>
						<Button
							title={t("productSearchModal.cancelAction")}
							size="lg"
							variant="cancellation"
							onClick={() => onClose(false)}
						/>
					</div>
				</Modal.body.actions>
			</Modal.body>
		</Modal.root>
	);
};
